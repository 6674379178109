.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold !important;
  line-height: 1.5;
  letter-spacing: 0.00938em ;
}

.MuiAccordion-rounded:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 64px;
  background-color: #3065b5;
}