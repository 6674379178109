input[type="checkbox"]{
    display: inline-block;
    /* display: 'inline-flex'; */
    flex-Direction: 'row';
    margin-left: -75px;
    position: absolute;
    margin-top: 5px; 
    
}
.labelstyle{
    margin-left:-75px;
   padding-left: 50px;
    display: inline-block;
    text-align: centre;
    z-index:200px;
    text-indent: -15px;
    top: -120px;
      /* position:absolute;  */
}
.card-title{
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn1{
    display: flex;
    justify-Content: right;

    padding-left: 3px;
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 2px;
    bottom: 75px;
}
.btn2{
    display:flex;
    justify-Content: left;
    margin-left: auto;
    position: absolute;
    bottom: 30px;
    left:130px;
}
.remove_cart_button
{
    height: 30px !important;
}
.booking-card{
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.cart-heading{
    color: #3065b5;
    font-weight: 700;
}
.cart-body{
    padding: 20px !important;
}
.cart-row{
    display: flex;
    flex-wrap: wrap-reverse !important;
}

@media screen and (max-width:428px){
    .cart-card{
        margin-top: 20px;
        padding: 20px 10px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
    .btn-group{
        margin-bottom: 10px;
    }
    .prescription-row{
    display: flex;
    flex-wrap: nowrap;
    }
}
.cart-card{
   
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
