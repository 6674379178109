.style {
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    display: inline-flexbox;
    position: relative; 
    display: block; 
    left: 10px; 
    top: 0px; 
    z-index: 9;
    width: 100%;
}

/* Modern and Clean Search Filter Styles */
.search-filter {
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: relative;
    top: -87px;
    width: 26%;
    left: 35%;
}

.search-filter:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.search-filter .card-header {
    background-color: #007bff;
    color: white;
    padding: 15px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.search-filter .card-title {
    margin: 0;
    font-weight: 600;
    font-size: 1.2rem;
}

.search-widgettest {
    margin-bottom: 15px;
}

.search-formtest .input-group {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 46px;
}

.search-formtest .form-control {
    border: none;
    padding: 12px 20px;
    background-color: white;
    transition: background-color 0.3s ease;
}

.search-formtest .form-control:focus {
    outline: none;
    background-color: #f1f3f5;
}

.search-formtest .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 12px 20px;
    transition: background-color 0.3s ease;
}

.search-formtest .btn-primary:hover {
    background-color: #0056b3;
}

/* .filter-widget {
    margin-bottom: 20px;
    top: -145px;
    left: 87%;
    width: 137px;
    margin-top: 10px;
} */

.filter-widget h4 {
    color: #333;
    /* margin-bottom: 15px; */
    font-weight: 600;
    /* border-bottom: 2px solid #007bff; */
    /* padding-bottom: 10px; */
    font-weight: 700;
}
.filter-widget h3 {
    color: #00398f;
    margin-bottom: 5px;
    font-weight: 800;
    /* border-bottom: 2px solid #007bff; */
    /* padding-bottom: 10px; */
    font-size: 1.5rem;
}
.custom_check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}

.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.custom_check:hover .checkmark {
    background-color: #ccc;
}

.custom_check input:checked ~ .checkmark {
    background-color: #007bff;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom_check input:checked ~ .checkmark:after {
    display: block;
}

.custom_check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

/* .style {
    margin-left: 10px;
    color: #495057;
    font-weight: 500;
}
.filter-widget {
    position: relative;
  } */
  
  /* .dropdown-container {
    position: relative;
  }
  
  .dropdown-header {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  } */
  
  /* .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 300px; 
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: none; 
    z-index: 10;
  } */
  
  /* Keep dropdown open when hovering over container or content */
  /* .dropdown-container:hover .dropdown-content,
  .dropdown-content:hover {
    display: block; 
    
  } */

  /* @media screen and (max-width:768px){
    .search-filter {
        background-color: #f8f9fa;
        border-radius: 12px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        position: relative;
        top: 13px;
        width: 70%;
        left: 15%;
    }

    .filter-widget {
        margin-bottom: 20px;
        position: relative;
        top: -132px;
        left: 80%;
        width: 110px;
        margin-top: 10px;
    }
  } */
  



  /* 4/12/2024 */
  .dropdown-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .dropdown-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .dropdown-content {
    display: grid;
    grid-template-columns: repeat(7, 1fr); 
    gap: 15px; 
    margin: 0px 142px;
  }
  
  
  .custom-card {
    /* padding: 10px; */
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.2s;
  }

  .custom-card:hover {
    background-color: #ebebeb;
  }
  
  .custom-card.selected {
    background-color: #dde8f4;
    color: #fff;
  }
  
  .custom-card:hover {
    transform: scale(1.05);
  }
  
  .card-content {
    padding: 10px;
  }
  
  .card-title {
    font-size: 1rem;
    font-weight: bold;
  }
  .cat-image{
    border-radius: 50%;
    width: 70px;
    height: 70px;
  }

  @media screen and (max-width:768px){
    .dropdown-content {
      display: grid;
      grid-template-columns: repeat(4, 1fr); 
      gap: 15px; 
      margin: 0px 0px;
    }
    .search-filter {
      background-color: #f8f9fa;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      position: relative;
      top: -80px;
      width: 50%;
      left: 25%;
  }
  .search-package{
    margin-top: 80px;
  }
  .doctor-grid{
    margin-top: -66px;
  }
  }
  
  @media screen and (max-width:428px){
   
    .search-filter {
      background-color: #f8f9fa;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      position: relative;
      top: -80px;
      width: 70%;
      left: 15%;
  }
 
  }
 
  
  