.testimonial-row{
  display: block !important;
}
.testimonial-col{
  display: block !important;
}
 blockquote {
  border-radius: var(--border-radius);
  padding: 10px;
}
.testimonial-heading h1 {
  font-weight: 700;
  color: #da6408;
  padding-bottom: 30px;
}
@media screen and (max-width:428px){
  .testimonial {
    padding: 0px 0 100px;
    background-color: #034392;
    margin: 0px 10px 50px 10px !important;
    border-radius: 20px;
    margin-bottom: 60px;
}
}
