/* General container styling */
.top-service-col {
  padding: 50px 0;
  background: linear-gradient(to right, #f9f9f9, #fff);
}

.service-box {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-box:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.service-box .card {
  border: none;
  border-radius: 12px;
  background-color: #fff;
}
