.sticky {
    position: sticky;
    top: 0;
    z-index: 100;
}




@media screen and (max-width:991px) {
    .search-box {
        display: none !important;
    }

    .customnav {
        display: none;
    }

    .download {
        display: none;

    }

    #mobile_btn {
        left: 0;
        margin-right: 0;
        padding: 0 15px;
        position: absolute;
        z-index: 99;
        margin-top: 15px;
        margin-left: -20px;
    }

    .logo {
        margin-left: 0rem !important;
    }

    .user-header {
        display: flex;
        padding: 10px 150px !important;
        height: 138px !important;
    }

    .logo-container {
        position: fixed;
        left: 11%;

    }

    /* .logo-container {
        position: absolute;
        right: 50%;
        left: 41%;

    } */

    /* .user-menu {
    position: relative;
    top: 50%;
    right: 30%;
} */

}

@media screen and (width<=428px) {
    .smallcart {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr) !important;
        position: fixed !important;
        bottom: -3%;
        background-color: #e8f0fb;
        left: 50px;
        /* border-radius: 20px; */
        
    }
   
}


@media screen and (min-width:991px) {
    .small-nav {
        display: none !important;
    }

    .smallcart {
        display: none !important;
    }

    .search-box {
        display: flex;
        /* border: 1px solid #e97a24; */
    }

    .search-box input {
        border: 1px solid #e97a24 !important;
    }

    .search-box select {
        color: white;
        background-color: #e97a24;
        width: 10rem;
        border: 1px solid #e97a24 !important;

    }

    .company-logo {
        margin: 10px 100px;
    }

    .download {
        margin-top: 20px;
        display: grid;
        /* margin: 10px 61rem; */
        position: absolute;
        right: 12rem;

    }

    .img-download {
        display: flex;
        width: 100px;
    }

    .search-box {
        width: 35rem;
    }

    .parent-container {
        /* margin-left: 17vw; */
        position: absolute;
        right: 31%;
        left: 29%;
    }

    .customnav {
        display: flex;
        background-color: #3065b5;
        width: 100%;
        padding-left: 40px;
        /* height: 50px; */
    }

    .customnav ul li {
        /* padding: 0 2vw; */
        height: 60px;
        /* margin: -17px 0; */

    }

    .customnav .lapnav li:nth-child(-n+7):hover {
        background-color: white;
        color: #3065b5;
        transition: all 0.3s ease;
    }

    .customnav .lapnav li:hover a {
        color: #3065b5 !important;
    }

    /* Option 1: Bottom line animation */
    /* .customnav .lapnav li:nth-child(-n+7) {
        position: relative;
    }

    .customnav .lapnav li:nth-child(-n+7)::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 3px;
        background-color: #e97a24;
        transition: width 0.3s ease;
    }

    .customnav .lapnav li:nth-child(-n+7):hover::after {
        width: 100%;
    } */

    /* For the link color change */
    /* .customnav .lapnav li:hover a {
        color: #e97a24;
    }
    .main-nav .active-link {
        position: relative;
        color: white !important;
    }
    
    .main-nav .active-link::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #e97a24;
    }
    
    .main-nav .active-link a {
        color: #e97a24 !important;
    } */
   

   
    
    .user-header {
        display: flex;
        padding: 0px 15px !important;
    }

    .nav-right {
        line-height: 10px;
    }

    .notify.dropdown {
        margin-left: -10px;
        padding: 17px 0px;
        margin-top: -12px;
    }

    .logo {
        padding: 0;
    }

    .main-nav li a {
        margin-top: 10px;
        /* line-height: 32px !important; */
        padding: 0 !important;
        color: white !important;
        font-size: 1vw !important;
        /* background-color: red; */
        /* height: 60px; */
        
    }

    .input-box {
        width: 26vw !important;
    }

    .noti-dropdown i {
        color: white;
    }

    .notify .badge {
        background-color: #fff !important;
        display: block;
        font-size: 10px;
        font-weight: bold;
        min-height: 15px;
        min-width: 15px;
        position: absolute;
        right: 11px !important;
        color: #1b5a90 !important;
        top: 20px !important;
    }

    .main-nav {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0 6vw !important;
    }

}



@media screen and (max-width:780px) {
    .main-nav {
        padding: 0;
        flex-direction: column;
        padding-left: 0;
        margin-top: 60px !important;
        list-style: none;
    }

    .user-header {
        display: flex;
        padding: 10px 15px;
        height: 74px !important;
    }

    .logo {
        display: inline-block;
        margin-right: 30px;
        margin-left: 15px !important;
        width: 160px;
        font-size: 30px;
        font-weight: 900;
    }

    #mobile_btn {
        left: 0;
        margin-right: 0;
        padding: 0 15px;
        position: absolute;
        z-index: 99;
        margin-top: 15px;
        margin-left: -20px;
    }

    .navnbar_1 {
        margin-left: 12px !important;
    }

    .main-nav {
        display: flex;
        position: absolute;
        top: 0px;
        left: 35px;
    }

    .nav-links {
        display: none;
    }

    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        background-color: blue;
        left: 0;
        top: 75px;
        transform: all 0.5s ease-out;
        width: 100%;

    }

    .home,
    .infrastructure,
    .test,
    .packages,
    .faqs,
    .articles,
    .login {
        color: white;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease;


    }

    .login {
        border: none;

    }

    #mobile-btn {
        display: block;
        position: absolute;
        font-size: 30px;
    }
}

/*.mobile-menu-link {
   grid-column: 2/4;
   position: relative;
   z-index: 99;
}

.mobile-menu-link {

background-color:white;
height:20rem;
display: grid;
grid-column: 2/5;
align-items: centre;
padding-left: 3rem;
transition: all 2s linear;
transform-origin:top;
box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px
           rgba(0,0,0,0.3) 0px 30px 60px -30px;


}*/
@media(max-width:800px) {
    nav .main-nav {
        height: 75vh;
        position: fixed;
        top: 0;
        right: 25;
        left: 25;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #3065b5;
        transition: top 1s ease;

    }

    .slide-bar {
        background-color: cornflowerblue;
        display: flexbox;
    }
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    /* vertical-align: 0.255em; */
    content: "";
    /* border-top: 0.3em solid; */
    /* border-right: 0.3em solid transparent; */
    /* border-bottom: 0; */
    /* border-left: 0.3em solid transparent; */
}

/* .main-nav {
  
   background-color:#3065b5;
} */
.navnbar_1 {
    margin-left: -50px !important;
}

.search-form,
.search-form .form-control,
.search-form .input-group,
.search-form .input-group-append .btn {
    border-radius: 0 !important;
    /* Remove border radius */
}

.main-nav .active-link {
    background-color: white;
    color: #3065b5 !important;
   
}

.main-nav .active-link a {
    color: #3065b5 !important;

}





@media screen and (min-width:2000px) {
    .parent-container {
        /* position: absolute; */
        /* margin: 0 29vw !important; */
        position: absolute;
        right: 31%;
        left: 36%;
    }
}

.notification-message {
    margin-bottom: 10px !important;
    line-height: 25px;
}

.notification-message span {
    color: rgb(70, 70, 70) !important;
    font-size: 11px !important;
    text-align: center;
}

.dropdown-item a {
    color: black !important;
    text-align: center;
}
.customnav{
    width: 100vw;
}

/* .customnav ul li .dropdown-item{
    color: black !important;
    text-align: center;
} */


.customnav ul li .dropdown-item {
    color: black !important;
    text-align: center;
    padding: -top;
    margin-top: 0px;
    margin-bottom: 0px !important;
}

.notification-message:hover {
    background-color: rgb(245, 245, 245);
}

@media screen and (max-width:429px) {
    .header-center {
        position: absolute !important;
        top: 54% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        width: 100% !important;
        /* Ensures the element takes the full width on mobile */
        text-align: center !important;
    }

    .user-menu {
        /* margin-left: -28vw !important; */
        margin-top: 54px !important;
        width: 100vw !important;
    }
}

@media screen and (max-width:991px) and (min-width:429px) {

    /* .user-menu {
        position: relative;
        top: 50%;
        right: 30%;
    } */
    .user-menu {
        /* float: right; */
        /* margin-right: -10px; */
        position: fixed !important;
        z-index: 99;
        top: 0rem;
        right: -1rem;
    }

    .user-header {
        display: flex;
        padding: 10px 150px !important;
        height: 90px !important;
    }

}

@media screen and(min-width:991px) {
    .mobile-login {
        display: none;
    }

}

.infra-container {
    max-width: 600px !important;
}

.notify-cart.dropdown {
    margin-top: -26px;
}