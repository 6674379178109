/* Main container */
.why-book-with-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  text-align: center;
  /* background: linear-gradient(135deg, #f9f5ff, #edf7ff); */
  border-radius: 1rem;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
}

.heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #e97a24;
  margin-bottom: 2rem;
  letter-spacing: 0.05rem;
}

/* Features container */
.features-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Feature card */
.feature-card {
  flex: 1;
  background: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  max-width: 350px;
  margin: 0 auto;
  /* background: linear-gradient(135deg, #f9f5ff, #c3dcf0); */
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.icon-wrapper {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #6b46c1; /* Purple shade */
  margin-bottom: 0.5rem;
  letter-spacing: 0.02rem;
}

.feature-description {
  font-size: 1rem;
  color: #6b7280; /* Gray shade */
  line-height: 1.5;
}

/* Icon styling */
.icon-container {
  position: relative;
  width: 8rem;
  height: 6rem;
  margin: 0 auto;
}
.whyusimage{
  width: 70px;
}