/* LatestArticles.css */
.card.post-widget {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  overflow: hidden;
}

.card-header {
  padding: 1.25rem;
  background: #f8f9fa;
  border-bottom: 1px solid #eaeaea;
 
}
.article-heading{
  background-color: #e3e0e0 !important;
}
.article-heading img{
  height: 25px;
  margin: 2px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.card-body {
  padding: 1.25rem;
}

.latest-posts {
  list-style: none;
  padding: 0;
  margin: 0;
}

.latest-posts li {
  display: flex;
  padding: 0px;
  margin-bottom: 15px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.latest-posts li:hover {
  background: #f8f9fa;
  transform: translateY(-2px);
}

.latest-posts li:last-child {
  margin-bottom: 0;
}

.post-thumb {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  margin-right: 15px;
  border-radius: 6px;
  overflow: hidden;
}

.post-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.latest-posts li:hover .post-thumb img {
  transform: scale(1.05);
}

.post-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post-info h4 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1a202c;
  margin: 0 0 8px 0;
  line-height: 1.4;
  transition: color 0.3s ease;
}

.latest-posts li:hover .post-info h4 {
  color: #4a90e2;
}

.post-info p {
  font-size: 0.9rem;
  color: #718096;
  margin: 0;
  display: flex;
  align-items: center;
}

.post-info p::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #718096;
  border-radius: 50%;
  margin-right: 8px;
}

.mb-2 {
  margin-bottom: 1rem;
}

/* Loading State Styles */
.loading-placeholder {
  animation: pulse 1.5s infinite;
  background: #f3f4f6;
  border-radius: 6px;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .card-header {
      padding: 1rem;
  }

  .card-body {
      padding: 1rem;
  }

  .card-title {
      font-size: 1.25rem;
  }

  .post-thumb {
      width: 80px;
      height: 80px;
  }

  .post-info h4 {
      font-size: 1rem;
  }

  .post-info p {
      font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .post-thumb {
      width: 60px;
      height: 60px;
  }

  .latest-posts li {
      padding: 10px;
  }

  .card-title {
      font-size: 1.1rem;
  }
}

/* Empty State Styles */
.empty-state {
  text-align: center;
  padding: 2rem;
  color: #718096;
}

/* Image Error Fallback */
.post-thumb img[src="null"],
.post-thumb img:not([src]) {
  background-color: #f3f4f6;
}

/* Accessibility Improvements */
.latest-posts li:focus {
  outline: 2px solid #4a90e2;
  outline-offset: 2px;
}

/* Optional: Scrollbar Styling */
.card-body {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 transparent;
}

.card-body::-webkit-scrollbar {
  width: 6px;
}

.card-body::-webkit-scrollbar-track {
  background: transparent;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 3px;
}