.health-concerns-container {
  background-color: #f0ecf8;
  padding: 2rem;
  color: white;
  text-align: center;
}

.health-concerns-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.health-concerns-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1.5rem;
}

.health-concern-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  color: #674EA7;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.health-concern-item:hover {
  transform: translateY(-5px);
}

.health-concern-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #674EA7;
}

.health-concern-label {
  font-size: 1rem;
  font-weight: bold;
}

.view-all-btn {
  background-color: #7B5DC6;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2rem;
  transition: background-color 0.3s ease;
}

.view-all-btn:hover {
  background-color: #5D4B9D;
}

@media (max-width: 768px) {
  .health-concerns-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .health-concern-item {
    padding: 1rem;
  }

  .health-concern-icon {
    font-size: 1.5rem;
  }

  .health-concern-label {
    font-size: 0.9rem;
  }

  .view-all-btn {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}