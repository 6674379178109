.nav-active {
    color:tomato;
}
.menu-toggle{
    display: none;
}
@media screen and (max-width:768px){
.dashboard-menu {
    position: relative;
  }
  
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: black;
    font-weight: 700;
    /* background-color: red; */
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .menu li {
    margin: 10px 0;
  }
  
  .menu.open {
    display: block;
  }
  
  @media (max-width: 768px) {
    .menu {
      display: none;
    }
  
    .menu-toggle {
      display: flex;
    }
  }
}

@media screen and (max-width:768px) {
  .widget-profile .profile-info-widget {
    display: flex !important;
    text-align: center;
  }

  .user-tabs .nav-tabs>a {
    padding: 5px !important;

  }

  .nav-tabs {
    border-bottom: 0;
    position: relative;
    background-color: #fff;
    padding: 5px 0;
    border: 0px solid #ddd !important;
    border-radius: 3px;
    display: grid !important;
    grid-template-columns: 50% 50%;
  }

  .user-tabs .nav-tabs>a {
    width: 100% !important;
    font-size: 16px;
    font-weight: 700 !important;
    border-bottom: 1px solid #e97a24 !important;
  }

  .change-photo-btn {
    width: 200px !important;
  }

  .upload-bookoncall {
    display: grid;
    grid-template-columns: 100%;
  }

  .user-tabs .nav-tabs>a.active,
  .user-tabs .nav-tabs>a.active:hover,
  .user-tabs .nav-tabs>a.active:focus {
    border-bottom-width: 3px;
    color: #e86c24;
    border-top: 1px solid #e97a24 !important;
    border-right: 1px solid #e97a24 !important;
    border-bottom: 1px solid #e97a24 !important;
    border-left: 7px solid #e97a24 !important;
    background-color: #fff;

  }

  /* Menu icon container */
  .menu-icon {
    display: grid;
    grid-template-columns: auto auto;
    /* Lines and dots side by side */
    grid-gap: 4px;
    /* Space between lines and dots */
    cursor: pointer;
    width: fit-content;
    margin: 0px 10px;
  }

  /* Styling for lines */
  .menu-icon .line {
    width: 20px;
    /* Length of the line */
    height: 3px;
    /* Thickness of the line */
    background-color: #e97a24;
    /* Line color */
    border-radius: 2px;
    /* Rounded edges */
    transition: all 0.3s ease-in-out;
  }

  /* Styling for dots */
  .menu-icon .dot {
    width: 4px;
    /* Diameter of the dot */
    height: 4px;
    /* Diameter of the dot */
    background-color: #e97a24;
    /* Dot color */
    border-radius: 50%;
    /* Makes it a perfect circle */
    transition: all 0.3s ease-in-out;
  }


  /* Button container styling */
  .menu-toggle {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .menu-toggle h4 {
    font-size: 16px;
    color: #333;
    margin-left: 10px;
    margin-top: 9px;
  }

  .form-control {
    border: 1px solid #3065b5 !important;
  }

  .clearbtn {
    color: white;
    background-color: #e76d27;
  }

  .sea {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 45px;
    gap: 10px;
  }

  .family-row {
    padding: 0px 20px !important;
  }

  .table-avatar {
    font-size: 14px !important;
  }

}

@media screen and (max-width:991px) and (min-width:769px) {
  .nav-tabs {
   
    display: grid !important;
    grid-template-columns: repeat(2,1fr) !important;
  }

  .user-tabs .nav-tabs>a {
    border: 0;
    border-bottom: 3px solid transparent;
    color: #3e3e3e;
    font-weight: 600;
    padding: 20px;
    width: 100% !important;
    text-align: center;
  }
}