/* Basic styles for blog card grid */
.blog-grid-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  justify-content: space-between;
}

.blog.grid-blog {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  margin-bottom: 30px;
}

.blog.grid-blog:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.blog-image {
  position: relative;
  overflow: hidden;
  /* border-bottom: 4px solid #007bff;  */
}
/* .blog-image:hover {
  
  border-bottom: 4px solid #007bff; 
} */


/* .blog-image::after {
  content: '';
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 0; 
  height: 4px; 
  background-color: #007bff; 
  transition: width 0.5s ease; 
}

.blog-image:hover::after {
  width: 100%; 
} */


.blog-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.blog-image:hover img {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.blog-content {
  padding: 8px;
  /* text-align: center; */
}

h3.text-muted {
  color: #007bff;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

h3.text-muted:hover {
  color: #007bff !important; /* Change color on hover */
}

.blog-content span {
  display: block;
  margin-top: 15px;
}

/* Ensure responsiveness */
@media (max-width: 768px) {
  .col-md-4 {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    width: 31%;
  }
}
