/* Sidebar container */
.sidebar {
  position: absolute;
  top: 40%;
  right: 0; /* Aligns with the edge of the screen */
  transform: translateY(20%);
  background-color: white;
  border: 1px solid #ddd;
  /* border-radius: 8px 0 0 8px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 80px; /* Default sidebar width */
  box-sizing: border-box;
  overflow: visible; /* Ensures content inside doesn't get clipped */

}

/* Toggle button */
.toggle-button {
  position: absolute;
  top: calc(40% - -81px);
  right: 0;
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #ddd;
  /* border-radius: 8px 0 0 8px; */
  font-size: 13px;
  color: #333;
  width: 29px;
  height: 26px;
  cursor: pointer;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #f0f0f0;
}

/* Individual items */
.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 15px; */
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-item:last-child {
  border-bottom: none;
}

.sidebar-item:hover {
  background-color: #f9f9f9;
}

/* Icon styles */
.sidebar-icon {
  font-size: 24px;
  color: #4a4a4a;
  margin-bottom: 5px;
}

/* Text styles (responsive behavior) */
.sidebar-text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: center;
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Ensures no overflow outside the sidebar */
  text-overflow: ellipsis; /* Adds "..." if the text overflows */
  max-width: 100%; /* Ensures the text fits within the sidebar */
}

/* Media queries for further responsiveness */
@media (max-width: 400px) {
  .sidebar {
    width: 120px; /* Shrinks the sidebar on small screens */
  }

  .sidebar-text {
    font-size: 12px; /* Adjusts text size for narrow sidebars */
  }
}

@media (max-width: 300px) {
  .sidebar {
    width: 100px; /* Minimum width for very small screens */
  }

  .sidebar-text {
    font-size: 10px; /* Shrinks text size further if necessary */
  }
}
