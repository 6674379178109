button:disabled,button[disabled]{
    border: 1px solid #999999;
    background-color:#cccccc;
    color:#666666;
   
}
button {
    border:1px solid #0066cc;
    background-color:#0099cc;
    color:#ffffff;

}
.donate-now input[type="radio"]{
    display: inline-block;
    /* display: 'inline-flex'; */
    flex-Direction: 'row';
    margin-left: -145px;
    position: absolute;
    margin-top: 3px; 
    /* margin-bottom: 20px; */

 
    
}

/* input[type='radio']:checked{
    background-color: #ffa500;
} */

/* *,
*:before,
*:after {
  box-sizing: border-box;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label:before {
  content: "";
  /* create custom radiobutton appearance */
  /* display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  margin-right: 3px;
  /* background-color only for content */
  /* background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%; */
/* } */ 

/* appearance for checked radiobutton */
/* input[type="radio"]:checked + label:before {
  background-color: #93e026;
} */ 
/* .donate-now label,
.donate-now input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

/* .donate-now input[type="radio"] {
    opacity: 0.01;
    z-index: 50;
  } */
  /* input[type="radio"]:checked+label,
.Checked+label {
  background: green;
} */
input:checked {
    background-color: #21f367;
  }

  /* .donate-now {
    /* list-style-type:none;
    /* margin:25px 0 0 0; */
    /* padding:0;
}  */

/* .donate-now li {
    float:left;
    margin:0 5px 0 0;
} */

.donate-now label {
  padding: -5px;
  /* border: 1px solid #CCC; */
  /* cursor: pointer; */
  z-index: -30;
}

/* .donate-now label:hover {
  background: #DDD;
} */

/* .radioselect label {
    padding:5px;
    border:1px solid #CCC; 
    cursor:pointer;
}

.radioselect label:hover {
    background:#DDD;
} */

/* .radioselect input[type="radio"]:checked+label,
.Checked+label {
  background:green;
} */

/* #btn-success{
    background-color: green;
} */

/* button[onClick ]{
background-color: darkblue;
} */

