/* BannerSlider.css */
.banner-container {
  width: 100%;
  max-width: 1200px;
  margin: 75px auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.banner-slider {
  position: relative;
  height: 250px;
}

.banner-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.banner-slide.active {
  opacity: 1;
}

.banner-content {
  display: flex;
  height: 100%;
  background: #f9f8f4;
}

.banner-text {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-text h2 {
  font-size: 32px;
  color: #5D4037;
  margin-bottom: 20px;
  font-weight: bold;
}

.banner-text .subtitle {
  font-size: 18px;
  color: #795548;
  margin-bottom: 10px;
}

.banner-text .note {
  font-size: 14px;
  color: #8D6E63;
  margin-bottom: 30px;
}

.banner-image {
  flex: 1;
  overflow: hidden;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-button {
  background-color: #5D4037;
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.book-button:hover {
  background-color: #4E342E;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}

.dots-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(93, 64, 55, 0.3);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #5D4037;
}

@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
  }

  .banner-text {
    padding: 20px;
  }

  .banner-text h2 {
    font-size: 24px;
  }

  .banner-text .subtitle {
    font-size: 16px;
  }

  .banner-slider {
    height: 600px;
  }
}