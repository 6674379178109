:root {
  --primary-color: #2563eb;
  --secondary-color: #10b981;
  --text-color: #333;
  --background-color: #f4f4f4;
  --white: #ffffff;
}

.about-page {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Breadcrumb Section */
.breadcrumb-section {
  background-color: var(--white);
  padding: 5px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.breadcrumb-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.breadcrumb-link:hover {
  color: var(--secondary-color);
}

.page-title {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin: 0;
}

/* Company Profile Section */
.company-profile {
  padding: 0px 0;
  background-color: var(--background-color);
}

.profile-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.profile-description {
  /* background-color: var(--white); */
  padding: 30px;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0,0,0,0.1); */
}

.profile-description h2 {
  color: #e97a24;
  margin-bottom: 15px;
}

.profile-highlights {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px 10px;
}

.highlight-card {
  background-color: var(--white);
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.highlight-card:hover {
  transform: translateY(-5px);
}

.highlight-icon {
  font-size: 3rem;
  display: block;
  margin-bottom: 15px;
}

/* Leadership Section */
.leadership-section {
  padding: 60px 0;
}

.section-title {
  text-align: center;
  color:#e97a24;
  margin-bottom: 20px;
  font-size: 2rem;
}

.directors-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.director-card {
  display: flex;
  align-items: center;
  /* background-color: var(--background-color); */
  /* padding: 30px; */
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0,0,0,0.1); */
}

.director-image-container {
  margin-right: 30px;
}

.director-image {
  width: 1000px;
  height: 300px;
  /* border-radius: 50%;
  object-fit: cover;
  border: 4px solid var(--primary-color); */
}

.director-details h3 {
  color: var(--primary-color);
  margin-bottom: 5px;
}

.director-role {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.director-quote {
  /* font-style: italic; */
  color: var(--text-color);
  padding-right: 37px;
}
/* 
.directors-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.director-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.director-card.image-right {
  flex-direction: row;
}

.director-card.image-left {
  flex-direction: row;
}

.director-image-container {
  flex: 1;
  text-align: center;
}

.director-image {
  width: 100%;
  max-width: 200px;
  border-radius: 8px;
}

.director-details {
  flex: 2;
  padding: 1rem;
}

.director-details h3 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #333;
}

.director-details .director-role {
  font-weight: bold;
  color: #777;
  margin-bottom: 1rem;
}

.director-details .director-quote ul {
  margin: 0;
  padding: 0;
  list-style-type: disc;
  padding-left: 1.5rem;
  line-height: 1.5;
} */


/* Why Choose Us Section */
.why-choose-us {
  background-color: var(--white);
  padding: 60px 0;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.feature-card {
  text-align: center;
  background-color: var(--background-color);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  flex: 100%;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 3rem;
  display: block;
  margin-bottom: 15px;
}

.feature-card h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

/* Certification Section */
/* NABL Certification Detailed Section */
.nabl-certification-detailed {
  background-color: var(--background-color);
  padding: 60px 0;
}

.nabl-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: start;
}

.nabl-image-container {
  background-color: var(--white);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nabl-certificate-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: contain;
}

/* .nabl-details {
  background-color: var(--white);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
} */

.nabl-details h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 1.8rem;
  border-bottom: 3px solid var(--primary-color);
  padding-bottom: 10px;
}

.nabl-info-sections {
  display: grid;
  gap: 20px;
}

.nabl-info-section {
  background-color: var(--background-color);
  padding: 10px 0px;
  border-radius: 10px;
}

.nabl-info-section h3 {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.nabl-info-section ul {
  padding-left: 20px;
  margin-top: 10px;
}

.nabl-info-section ul li {
  margin-bottom: 5px;
  list-style-type: disc;
}

/* Responsive Design for NABL Section */
@media (max-width: 768px) {
  .nabl-content-wrapper {
    grid-template-columns: 1fr;
  }
}
/* Responsive Design */
@media (max-width: 768px) {
  .profile-grid,
  .directors-grid,
  .features-grid {
    grid-template-columns: 1fr;
  }

  .director-card {
    flex-direction: column;
    text-align: center;
  }

  .director-image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width:428px){
  .profile-highlights {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px 10px;
}
.profile-grid, .directors-grid, .features-grid {
  grid-template-columns: 100%;
}
}

.carousel-inner {
  height: 80vh; /* Full screen height */
}

.carousel-item img {
  object-fit: cover; /* Ensures the image covers the full area */
  height: 80vh; /* Match the height of the carousel */
  width: 100%; /* Full width */
}

