/* Wrapper for the buttons */
.social-icons {
  position: fixed !important;
  top: 80% !important;
  right: 20px !important;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 16px; /* Space between buttons */
  z-index: 9999; /* Ensure buttons are on top */
  box-shadow: 0 2px -1px hsla(60, 2%, 74%, .6);
}

/* Common expandable button styles */
.expandable-button  {
  background-color: #007bff; /* Default blue for phone */
  color: white;
  border: none;
  border-radius: 50px;
  display: flex;
  flex-direction: column; /* Stack text vertically inside the button */
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 50px;
  width: 50px; /* Initial size */
  cursor: pointer;
  transition: width 0.3s ease, background-color 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Prevent shift of buttons */
}

/* Expanded state */
.expandable-button.expanded {
  width: 200px; /* Expanded width */
  height: auto; /* Allow height to grow with the text */
}

/* WhatsApp-specific styles */
.expandable-button.whatsapp {
  background-color: #61c857; /* WhatsApp green */
}

.expandable-button.whatsapp.expanded {
  background-color: #61c857; /* Darker green when expanded */
}

/* Icon inside the button */
.whatsappicon {
  font-size: 24px;
  flex-shrink: 0;
  display: inline-block !important;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1)); /* Optional: adding shadow for better visibility */
  transition: transform 0.3s ease;
  position: absolute;  /* Position it relative to the parent button */
  top: 50%;  /* Vertically center */
  left: 10%; /* Horizontally center */
  transform: translate(-50%, -50%); /* Ensure exact center alignment */
  padding-left: 5px;
}

/* Contact text inside the button */
.contact-text {
  opacity: 0; /* Hidden when collapsed */
  transition: opacity 0.3s ease;
  text-align: center; /* Center the text inside the button */
}

.expandable-button.expanded .contact-text {
  opacity: 1; /* Fade in when expanded */
}

.contact-text span {
  display: block; /* Make each piece of text stack vertically */
  font-size: 14px;
}

/* Optional styles to enhance the button's appearance */
.expandable-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.expandable-button.whatsapp:hover {
  background-color: #25d366; /* Darker green on hover for WhatsApp */
}

