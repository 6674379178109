/* Enhanced styling for existing classes */
.doctor-widgets {
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: #fafdff;
  overflow: hidden;
  padding: 10px !important;
  
}

.doctor-widgets:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

/* .doc-info-left {
  display: flex;
  align-items: center;
  padding: 15px;
} */

.doctor-imgs {
  /* margin-right: 20px; */
  text-align: center;
}

.doctor-imgs img {
  border-radius: 10px;
  transition: transform 0.3s ease;
  cursor: pointer;
  width: 80px;
}

.doctor-imgs img:hover {
  transform: scale(1.05);
}

.doc-info-conts {
  flex-grow: 1;
}

.doc-names {
  color: #333;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
  text-align: center;
  height: 40px;
}

.doc-names:hover {
  color: #007bff;
}

.doc-specialitys {
  color: #6c757d;
  margin-bottom: 10px;
  height: 89px;
}

/* .doc-info-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background-color: #fff;
} */

.clini-infoss {
  margin-bottom: 15px;
}

.clini-infoss ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.clini-infoss li {
  display: flex;
  align-items: center;
  color: #28a745;
  font-weight: 700;
  font-size: 19px;
  margin-bottom: 0px;
  position: relative;
  top: 16px;
  justify-content: center;
  align-items: center;
}

.clini-infoss .fa-info-circle {
  margin-left: 10px;
  color: #6c757d;
  cursor: pointer;
  transition: color 0.3s ease;
}

.clini-infoss .fa-info-circle:hover {
  color: #007bff;
}

.clinic-bookingss {
  display: flex;
  flex-direction: row;
  gap: 7px;
  padding: 12px;
}

.btn-detail, .btn-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
}

.btn-detail {
  background-color: #007bff;
  color: white;
  border: none;
}

/* Grid Layout for Test Cards */
.card-containers {
  display: grid !important;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important; */
  grid-template-columns: repeat(5, 1fr);
  gap: 20px !important;
  padding: 0px 20px !important;
  position: relative;
  top: -80px;
}


@media (max-width: 991px) {
  .card-containers {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .card-containers {
      grid-template-columns: 1fr;
  }
}

/* @media screen and (min-width:991px){
  .card-containers {
   margin-top: -121px;
  }
} */



